import React from "react";
import { Helmet } from "react-helmet";

const LocalBusiness = () => (
  <Helmet>
    <script type="application/ld+json">
      {`{
                  "@context": "https://schema.org",
                  "@type": "LocalBusiness",
                  "name": "Goled Eyes of Munroe | Golden Retiever Zucht",
                  "image": "https://www.westermanns.com/static/f328691b987c34b9e064c36292d7b403/e4c47/20131030012.webp",
                  "email": "margit@westermanns.com",
                  "url": "https://westermanns.com",
                  "telephone": "+4972469418513",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Hansjakobstraße 18",
                    "addressLocality": "Malsch",
                    "postalCode": "76316",
                    "addressCountry": "DE"
                  },
                  "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 48.882901925502544, 
                    "longitude": 8.318837661734221
                  },
                  "sameAs": [
                    "https://www.westermanns.com"
                  ] 
            }`}
    </script>
  </Helmet>
);

export default LocalBusiness